import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import { setEventsFilters } from 'actions';
import { fetchGroups, fetchEvents } from 'thunks';
import TTPSelect from "common/TTPSelect";
import GroupsFilters from "./GroupsFilters";
import MailingListFilters from "./MailingListFilters";
import {setCurrentEmailCampaignData} from "../../../../actions/actions/currentCampaign";
import {
  isEventPast,
  sortEventByStartDateTime,
  transformDateFormat,
  ucFirst
} from "../../../../services/common";

@connect((store) => {
  return {
    events: store.filters.events.items,
    language: store.params.lng,
    campaignLanguage: store.currentCampaign.email.language,
    eventFilters: store.currentCampaign.email.eventFilters,
    commitmentScoreRange: store.currentCampaign.email.commitmentScoreRange,
  }
})
export default class ContactFilters extends Component {

  constructor(props) {
    super(props);
    const {commitmentScoreRange} = this.props;
    const commitmentScore = (typeof commitmentScoreRange === 'string') ? commitmentScoreRange.split(',') : commitmentScoreRange;
    this.state = {
      rangeValue: {min: commitmentScore[0] ?? commitmentScore.min , max: commitmentScore[1] ?? commitmentScore.max},
      eventsType: 'future',
    };
  }

  handleChangeCommitmentScoreRange = () => {
    const {dispatch} = this.props;
    dispatch(setCurrentEmailCampaignData({commitmentScoreRange: this.state.rangeValue}));
  };

  async componentWillMount() {
    const {recipientType, dispatch, eventFilters} = this.props;
    dispatch(fetchGroups(recipientType));
    await dispatch(fetchEvents());
    const { events } = this.props;
    let eventsType = 'future';

    eventFilters.forEach(filter => {
      if (filter.property == 'guest.event' && filter.operator == 'nin') {
        filter.value.forEach(eventId => {
          const event = events.find(e => e.id.toString() === eventId);
          if (event && isEventPast(event)) {
            eventsType = 'past';
          }
        });
      }
    });

    this.setState({ eventsType });
  }

  renderListOption(option) {
    return <div className="select-option">
      <span className="select-option__label">{option.label}</span>
      <span className="select-option__content">{option.subscribers} {_('subscribed contacts')}</span>
    </div>;
  }


  handleEventChange = (events) => {
    this.props.dispatch(setEventsFilters({ property: 'guest.event', operator: 'nin', value: events }))
  }

  render() {
    const { events, eventFilters, campaignLanguage } = this.props;
    const { eventsType } = this.state;

    let filterMapping = (eventFilters || []).reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});
    let excludedEventGuests = filterMapping['guest.event-nin'] || "";

    let lng = ucFirst(campaignLanguage);
    let sortedEvents = sortEventByStartDateTime(events, 'DESC', eventsType)
    let eventOptions = sortedEvents?.map(event => {
      const getEventName = () => {
        if (lng !== 'All' && event[`name${lng}`]) {
          return event[`name${lng}`];
        }
        // Fallback order: Fr -> Nl -> En
        return event['nameFr'] || event['nameNl'] || event['nameEn'] || 'Unnamed Event';
      };

      const eventName = getEventName();
      const eventStartDate = transformDateFormat(event['startDateTime']);

      return {
        label: `${eventStartDate} || ${eventName}`,
        id: event.id.toString()
      };
    });

    return (
      <div>
        <div className="row">
          <MailingListFilters />
          <div className="columns small-6 columns-padding">
            <div className="sidebar__form-control">
              <h1 className="param__title">{_('exclude event registrants')}
                <span style={{float: "right"}}>
                <span style={{marginLeft: '10px'}}>
                    <input type="radio" name='future' style={{margin: 'unset'}} defaultChecked={true} checked={this.state.eventsType === 'future'} onChange={() => this.setState({...this.state, eventsType: 'future'})}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('future')}</span>
                  </span>
                  <span style={{margin: '0 10px 0 10px '}}>
                    <input type="radio" name='replay' style={{margin: 'unset'}} defaultChecked={false} checked={this.state.eventsType === 'past'} onChange={() => this.setState({...this.state, eventsType: 'past'})}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('past')}</span>
                  </span>
                </span>
              </h1>
              <TTPSelect
                values={excludedEventGuests}
                placeholder={_('Select event')}
                options={eventOptions}
                onChange={this.handleEventChange} />
            </div>
          </div>
        </div>
        <GroupsFilters />
        {/*<div className="row slider-container medium-4">*/}
        {/*  <h1 className="param__title">{_('commitment score')}</h1>*/}
        {/*  <RangeSlider*/}
        {/*    maxValue={100}*/}
        {/*    minValue={0}*/}
        {/*    value={this.state.rangeValue}*/}
        {/*    onChange={value => this.setState({ rangeValue: value })}*/}
        {/*    onChangeComplete={this.handleChangeCommitmentScoreRange} />*/}
        {/*</div>*/}
      </div>
    );
  }
}
