import axios from "../axios-api";

export const getNewsletterArticles = ({ token, clientId }) => {
  const requestUrl = `/blog/newsletter-all-articles`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      id: clientId,
    },
  });
};

export const getNewsletterArticlesByPeriod = ({
  token,
  clientId,
  periodConfig,
  month,
  year,
  plan,
}) => {
  const requestUrl = `/blog/newsletter-articles-list`;
  let filter = [
    {
      property: "organization",
      value: clientId,
      operator: "eq",
    },
    {
      property: "month",
      value: month,
      operator: "eq",
    },
    {
      property: "year",
      value: year,
      operator: "eq",
    },
    {
      property: "periodConfig",
      value: periodConfig,
      operator: "eq",
    },
    {
      property: "plan",
      value: plan,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
    },
  });
};

export const updateIsUsedNewsletterArticles = ({
  token,
  articleNewsletterId,
  clientId,
}) => {
  const requestUrl = `/blog/newsletter-article/update-is-used`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", articleNewsletterId);
  formData.append("clientId", clientId);

  return axios.post(requestUrl, formData);
};
